import React, { useEffect } from 'react';
import { Fade } from 'react-awesome-reveal';
import styles from 'styles/JoinUs.module.scss';

const JoinUs: React.FC = () => {
  useEffect(() => {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  return (
    <>
      <section id="section1" className={styles.JoinUsSection1}>
        <div style={{ whiteSpace: 'nowrap' }}>
          <div className={styles.JoinUsTitleBox}>
            <h1>Abyssal Aerospace</h1>
            <div>입사 지원 안내</div>
          </div>
        </div>
        <h1>어비셜과 함께, 미래와 함께.</h1>
        <h2>
          어비셜과 함께,
          <br />
          미래와 함께.
        </h2>
      </section>

      <section id="section2" className={styles.JoinUsSection2}>
        <Fade duration={2000} triggerOnce>
          <p>
            지금까지 음속 발사체 개발에 성공하였으며, 복합 추진제를 이용한 우주
            발사체 프로젝트와 100kgf 급의 액체 로켓 추진기 프로젝트를 시작으로
            점차 고성능 고추력의 엔진을 개발하여 아마추어 항공우주 기술을
            발전시키고, 최종적으로는 고중량의 탑재체를 LEO에 안착시킬
            예정입니다.
            <br />
          </p>
        </Fade>
      </section>

      <section id="section3" className={styles.JoinUsSection3}>
        <h2>역사</h2>
        <Fade duration={2000} triggerOnce>
          <div className={styles.JoinUsHistoryBox}>
            <div>2017</div>
            <h1>
              강준서 대표에 의해 민간 항공우주 연구 조직 어비셜 항공우주 설립
            </h1>
          </div>
        </Fade>
        <Fade duration={2000} delay={500} triggerOnce>
          <div
            className={styles.JoinUsHistoryBox}
            style={{ textAlign: 'right' }}
          >
            <div>2019</div>
            <h1>
              어비셜 항공우주의 심해 아이덴티티와 초기형 돌고래 로고가 공개됨
            </h1>
            <h1>ASLV-S1 : 헥터 프로젝트 제시</h1>
          </div>
        </Fade>
        <Fade duration={2000} delay={500} triggerOnce>
          <div className={styles.JoinUsHistoryBox}>
            <div>2020</div>
            <h1>ASLV-S1 : 핵터 프로젝트 1,2차 지상 연소시험 진행</h1>
            <h1>ASLV-L1 : 바나듐 프로젝트 제시</h1>
          </div>
        </Fade>
        <Fade duration={2000} delay={500} triggerOnce>
          <div
            className={styles.JoinUsHistoryBox}
            style={{ textAlign: 'right' }}
          >
            <div>2021</div>
            <h1>ASLV-S1: 핵터 프로젝트의 2, 3차 지상 연소시험 진행</h1>
            <h1>
              경기도 화성시 우음도에서 ASLV-S1 : 헥터 프로젝트의 발사 진행
            </h1>
            <h1>ASLV-S3 : 피토휘 프로젝트 개발 착수</h1>
            <h1>ASLV-S4 : 알롭스 프로젝트 개발 착수</h1>
            <h1>어비셜 항공우주 부설 연구소 설립</h1>
          </div>
        </Fade>
      </section>

      <section id="section4" className={styles.JoinUsSection4}>
        {/*<div style={{ flexBasis: '90%' }}>
          <h2>입사 지원서</h2>
          <div className={styles.JoinUsForm}>
            <iframe
              style={{ width: '100%', height: '100%', border: 'none' }}
              src={
                process.env.REACT_APP_FORM_JOINUS // 이곳에 입사 지원서 URL 입력
              }
            />
            </div>
        </div>*/}
        <div className={styles.JoinUsDescription}>
          <h2>모집 대상</h2>
          <ul>
            <li>
              아마추어 항공우주 기술에 관심이 많은 학생 또는 아마추어 개발자
            </li>
            <li>희망 진로가 항공우주 기술자인 공학도</li>
          </ul>

          <h2>기타 안내 사항</h2>
          <ul>
            <li>
              신규 팀원은 명함, 단체 티셔츠 등의 제공을 위해 3만 원의 가입비를
              수금하고 있습니다.
            </li>
            <li>
              개발비 및 운영비 조달을 위해 한 분기 당 5만 원씩 회비를 수금하고
              있습니다. ( 1년 20만 원 )
            </li>
            <li>
              활동률이 저조하거나 팀에 부정적 영향을 미친다면 정관에 따라 제명될
              수 있습니다.
            </li>
            <li>1년에 2번 이상의 오프라인 정기모임을 진행합니다.</li>
            <li>지원자의 역량에 따라 별도의 면접이 실시될 수 있습니다.</li>
          </ul>
          <a
            href={process.env.REACT_APP_FORM_JOINUS}
            style={{ textDecoration: 'none', color: 'skyblue' }}
          >
            <h2>지원하기</h2>
          </a>
        </div>
      </section>
    </>
  );
};

export default JoinUs;
