import Metaspace from 'assets/sponsor/Metaspace.png';
import Satrec from 'assets/sponsor/Satrec.png';
import Setsystem from 'assets/sponsor/Setsystem.png';
import Gopro from 'assets/sponsor/Gopro.png';
import Fiberpro from 'assets/sponsor/Fiberpro.png';
import Contec from 'assets/sponsor/Contec.jpeg';
import Cnucns from 'assets/sponsor/Cnucns.png';
import Cnulinc from 'assets/sponsor/Cnulinc.png';
import Kosac from 'assets/sponsor/Kosac.png';
import kcmfsemi from 'assets/sponsor/kcmfsemi.png';
import Bokgwon from 'assets/sponsor/Bokgwon.png';
import dsccenter from 'assets/sponsor/dsccenter.png';
import kcmfdemi from 'assets/sponsor/kcmfdemi.png';
import msit from 'assets/sponsor/msit.png';

export default {
  large: [
    { name: 'fiberpro', src: Fiberpro, href: 'http://www.fiberpro.co.kr/' },
    { name: 'contect', src: Contec, href: 'https://kr.contec.kr/' },
  ],
  medium: [
    { name: 'metaspace', src: Metaspace, href: 'http://metaspace.co.kr/' },
    { name: 'satrec', src: Satrec, href: 'http://satreci.com/' },
    { name: 'setsystem', src: Setsystem, href: 'http://www.setsystem.co.kr/' },
    { name: 'gopro', src: Gopro, href: 'https://gopro.com/ko/kr/' },
    { name: 'cnucns', src: Cnucns, href: 'https://cns.cnu.ac.kr/cns/index.do' },
    {
      name: 'cnulinc',
      src: Cnulinc,
      href: 'https://linc.cnu.ac.kr/linc/index.do',
    },
    { name: 'kosac', src: Kosac, href: 'https://www.kosac.re.kr/main' },
    {
      name: 'kcmfsemi',
      src: kcmfsemi,
      href: 'https://kcmf.or.kr/KCMF/contents/KCMF050221.do',
    },
    { name: 'bokgwon', src: Bokgwon, href: 'https://www.bokgwon.go.kr/' },
    { name: 'dsccenter', src: dsccenter, href: 'http://www.dsccenter.kr/' },
    {
      name: 'kcmfdemi',
      src: kcmfdemi,
      href: 'https://kcmf.or.kr/KCMF/contents/KCMF050521.do',
    },
    { name: 'msit', src: msit, href: 'http://www.msit.go.kr/' },
  ],
};
